<template>
  <div class="orderCost">
    <el-card class="box-card father-card"
             shadow="never">
      <el-row class="btns">
        <el-form ref="form"
                 :model="form"
                 inline>
          <el-form-item label="订单编号">
            <el-input v-model="form.orderNo"
                      placeholder="请输入订单编号"
                      clearable></el-input>
          </el-form-item>
          <el-form-item label="项目名称">
            <el-input v-model="form.dispatchItem"
                      placeholder="请输入项目名称"
                      clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="success"
                       icon="el-icon-search"
                       @click="search()">搜索</el-button>
            <el-button v-if='isOrderCompany' v-permission="['/otherFeeAdd']"
                       type="primary"
                       icon="el-icon-plus"
                       @click="add()">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="tab">
        <!-- 表格区域 -->
        <el-table ref="multipleTable"
                  :data="List"
                  tooltip-effect="dark"
                  style="width: 100%"
                  highlight-current-row
                  stripe
                  border
                  :header-cell-style="rowClass">
          <el-table-column prop="orderNo"
                           label="订单编号"
                           align="center"></el-table-column>
          <el-table-column prop="dispatchItem"
                           label="项目名称"
                           align="center"></el-table-column>
          <el-table-column prop="dispatchFee"
                           label="项目费用(元)"
                           align="center"></el-table-column>
          <el-table-column prop="remark"
                           label="备注"
                           align="center"></el-table-column>
          <el-table-column prop="createBy"
                           label="创建人"
                           align="center"></el-table-column>
          <el-table-column prop="createTime"
                           label="创建时间"
                           align="center"></el-table-column>
          <!-- <el-table-column label="详情" align="center">
            <template slot-scope="scope">
              <el-button size="mini" @click="look(scope.row)" type="success">查看详情</el-button>
            </template>
          </el-table-column> -->
          <el-table-column label="操作"
                           align="center">
            <template slot-scope="scope">
              <el-popconfirm v-if='isOrderCompany' v-permission="['/otherFeeDel']"
                             confirm-button-text='确定'
                             cancel-button-text='不用了'
                             icon="el-icon-info"
                             icon-color="red"
                             title="确定要删除该订单费用吗？"
                             @confirm="ahead(scope.row)">
                <el-button type="danger"
                           slot="reference">删除</el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handlePageNumChange"
                       :current-page="paging.PageNo"
                       :page-sizes="[1, 10, 20, 50]"
                       :page-size="paging.pageSize"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="total"></el-pagination>
        <!-- 订单详情弹出框 -->
        <el-dialog :visible.sync="orderDialogFormVisible"
                   class="order-dialog">
          <span slot="title"
                class="dialog-Title">订单其他费用记录</span>
          <el-form :model="orderForm"
                   inline
                   ref="orderForm">
            <div class="xbrogcorf">

              <div class="cflex">
                <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">订单编号</div>
                <div style="padding: 10px 20px;width: 200px;">{{orderForm.orderNo}}</div>
              </div>
              <div class="cflex">
                <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">项目名称</div>
                <div style="padding: 10px 20px;width: 200px;">{{orderForm.dispatchItem}}</div>
              </div>
            </div>
            <div class="xbrogcorf">
              <div class="cflex">
                <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">项目费用</div>
                <div style="padding: 10px 20px;width: 200px;">{{orderForm.dispatchFee}}</div>
              </div>
            </div>
            <div class="xbrogcorf">
              <div class="cflex">
                <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">备注</div>
                <div style="padding: 10px 20px;width: 200px;">{{orderForm.remark}}</div>
              </div>
            </div>
            <!-- <el-row>
              <el-form-item label="订单编号" prop="orderNo">
                <el-input v-model="orderForm.orderNo" autocomplete="off" :disabled="isdisable"></el-input>
              </el-form-item>
              <el-form-item label="项目名称" prop="dispatchItem">
                <el-input v-model="orderForm.dispatchItem" autocomplete="off" :disabled="isdisable"></el-input>
              </el-form-item>
            </el-row>
            <el-row>
              <el-form-item label="备注" prop="remark">
                <el-input v-model="orderForm.remark" autocomplete="off" :disabled="isdisable"></el-input>
              </el-form-item>
            </el-row> -->
          </el-form>
          <div slot="footer"
               class="dialog-footer">
            <el-button type="primary"
                       @click="orderDialogFormVisible=false">关 闭</el-button>
          </div>
        </el-dialog>
        <!-- 添加其他费用弹出框 -->
        <el-dialog :visible.sync="orderDialogotherexpenses"
                   class="order-dialog">
          <span slot="title"
                class="dialog-Title">添加订单其他费用</span>
          <el-form :model="addorderotter"
                   inline
                   ref="addorderotter">
            <el-popover placement="bottom-start"
                        v-model="isVerification"
                        trigger="click"
                        class="dy-popover">
              <div class="dy-content">
                <!-- <div class="content-head" style="display: flex;">
                    <div class="head-input">
                      <el-input style="width: 100%"  placeholder="请输入订单编号" ></el-input>
                    </div>
                    <el-button type="primary" size="mini" @click="searchorderNo(shiorderForm.orderNo)" >搜索</el-button>
                    <el-button type="info" size="mini" @click="clrCarNum()">重置</el-button>
                  </div> -->
                <div class="content-table">
                  <el-table :data="shiorderForm"
                            height="500"
                            border
                            style="width: 100%">
                    <el-table-column prop="orderNo"
                                     label="订单编号"
                                     width="150"></el-table-column>
                    <el-table-column prop="memberName"
                                     label="下单用户"></el-table-column>
                    <el-table-column prop="memberMobile"
                                     label="手机号码"></el-table-column>
                    <el-table-column label="订单状态"
                                     align="center">
                      <template slot-scope="scope">
                        <el-tag effect="dark"
                                v-if="scope.row.status === '0'">{{ "新增" }}</el-tag>
                        <el-tag effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '1'">{{ "已派单" }}</el-tag>
                        <el-tag effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '2'">{{ "已接单" }}</el-tag>
                        <el-tag effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '3'">{{ "变更" }}</el-tag>
                        <el-tag effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '9'">{{ "到达出发地" }}</el-tag>
                        <el-tag effect="dark"
                                type="success"
                                v-else-if="scope.row.status === '10'">{{ "到达目的地" }}</el-tag>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作"
                                     width="70">
                      <template slot-scope="scope">
                        <el-button type="primary"
                                   size="small"
                                   @click="selorderNo(scope.row)">确认</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                  <pagination v-show="shiorderPage.total>0"
                              :total="shiorderPage.total"
                              :page.sync="shiorderPage.PageNo"
                              :limit.sync="shiorderPage.pageSize"
                              @pagination="getshiOrderList" />

                </div>
              </div>

              <el-row slot="reference">
                <el-col :span="24">
                  <el-form-item label="订单编号">
                    <el-input placeholder="请选择订单编号"
                              v-model="addorderotter.orderNo"
                              readonly="" />
                  </el-form-item>
                </el-col>
              </el-row>
              <!-- <div class="xbrogcorf"
                   slot="reference">
                <div style="background-color: #F3F6FA;padding: 10px;width: 110px;text-align: center;">订单编号</div>
                <input placeholder="请选择订单编号"
                       v-model="addorderotter.orderNo"
                       disabled
                       style="border: 0px;padding-left: 15px;outline: none;width: 100%;" />
              </div> -->
            </el-popover>
            <!-- <div class="xbrogcorf">
                  <div class="cflex">
                      <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">订单编号</div>
                      <input placeholder="请输入订单编号" v-model="addorderotter.orderNo"/>
                  </div>
              </div> -->
            <el-row>
              <el-col :span="24">
                <el-form-item label="项目名称">
                  <el-input placeholder="请输入项目名称"
                            v-model="addorderotter.dispatchItem" />
                  <el-tag @click="addorderotter.dispatchItem=item.key" disable-transitions  v-for="item in nameTag" :key="item.value" size="medium " type="success">{{item.key}}</el-tag>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="项目费用">
                  <el-input placeholder="请输入项目费用"
                            v-input-limit="2"
                            v-model="addorderotter.projectcost">
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注信息">
                  <el-input type="textarea"
                            placeholder="请输入备注信息"
                            v-model="addorderotter.remark" />
                </el-form-item>
              </el-col>
            </el-row>

            <!-- <div class="xbrogcorf">
                  <div class="cflex">
                      <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">项目名称</div>
                      <input placeholder="请输入项目名称" v-model="addorderotter.dispatchItem" style="width: 550px;"/>
                  </div>
              </div>
              <div class="xbrogcorf">
                  <div class="cflex">
                      <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">项目费用</div>
                      <input placeholder="请输入项目费用" v-input-limit="2" v-model="addorderotter.projectcost" style="width: 550px;"/>
                  </div>
              </div>
              <div class="xbrogcorf">
                  <div class="cflex">
                      <div style="background-color: #F3F6FA;padding: 10px;width: 100px;text-align: center;">备注</div>
                      <input placeholder="请输入备注信息" v-model="addorderotter.remark" style="width: 550px;"/>
                  </div>
              </div> -->

          </el-form>
          <div slot="footer"
               class="dialog-footer">
            <el-button type="primary"
                       @click="addotherexpenses()">确 认</el-button>
          </div>
        </el-dialog>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { isOrderCompany } from '@/utils'

export default {
  name: 'orderCost',
  components: { Pagination },
  data () {
    return {
      isOrderCompany: isOrderCompany(),
      List: [], // 表格数据源
      form: { orderNo: '' }, // 搜索表单
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      total: 0, // 总的数据条数
      orderDialogFormVisible: false,
      orderDialogotherexpenses: false,
      orderForm: {},
      shiorderPage: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1, // 当前页数
        total: 0
      },
      shiorderForm: {},
      isVerification: false,
      addorderotter: {
        dispatchItem: ''
      },
      orderFormRules: {
        dispatchItem: [
          { required: true, message: '请输入项目名称', trigger: 'blur' }
        ]
      }, // 编辑资料表单验证规则
      isdisable: true,
      nameTag: []
    }
  },
  created () {
    // 获取当前列表
    this.getOrderList()
    //
    this.getshiOrderList()
    this.getNameTag()
  },
  methods: {
    // 获取项目名称标签
    async getNameTag () {
      const { data: res } = await this.$http.post('/pmService/sysConfig/list', {
        type: 'OTHER_FEE_NAME'
      })
      this.nameTag = res.data
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换每页显示数量时触发
    async handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      await this.getOrderList()
    },
    // 切换页码时触发
    async handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      await this.getOrderList()
    },
    // 获取订单其他费用记录
    async getOrderList () {
      const { data: res } = await this.$http.post('/pmService/tInfoDispatchFee/getTInfoDispatchFeeList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        headers: {
          AUTH_TYPE: 'orderOtherFee'
        }
        // "orderType": '0'
      })
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')
      this.List = res.data.records
      this.total = +res.data.total
      console.log(res)
    },
    // 获取还未结束的订单
    async getshiOrderList () {
      const { data: res } = await this.$http.post('/userManageServer/pay/selectOrderHistoryDispatchList', {
        pageSize: this.shiorderPage.pageSize,
        pageNo: this.shiorderPage.PageNo
      })
      this.shiorderForm = res.data
      this.shiorderPage.total = Number(res.pageBean.pageDataCount)
      if (res.statusCode !== '200') return this.$message.error('获取订单列表失败')

      console.log(this.shiorderForm)
    },

    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
    //   if (this.form.orderNo === '') {
    //     this.getOrderList()
    //   } else {
      // 调接口，进行查询
      this.paging.PageNo = 1
      const { data: res } = await this.$http.post('/pmService/tInfoDispatchFee/getTInfoDispatchFeeList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo,
        //   orderNo: this.form.orderNo,
        //   dispatchItem: this.form.dispatchItem,
        ...this.form,
        headers: {
          AUTH_TYPE: 'orderOtherFee'
        }
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('查询失败')
      this.List = res.data.records
      this.total = +res.data.total
    //   }
    },
    // 点击添加(确定)按钮触发
    async addotherexpenses () {
      const { orderNo, dispatchItem, projectcost } = this.addorderotter
      if (!orderNo || !dispatchItem || !projectcost) return this.$message.warning('请输入完整信息')
      console.log(Number(projectcost), '成功')
      if (Number(projectcost) <= 0) return this.$message.warning('金额需要大于0')
      console.log(Number(projectcost), '2')
      //   return false
      const { data: res } = await this.$http.post('/pmService/tInfoDispatchFee/addTInfoDispatchFee', {
        orderNo: this.addorderotter.orderNo,
        dispatchItem: this.addorderotter.dispatchItem,
        dispatchFee: this.addorderotter.projectcost,
        remark: this.addorderotter.remark,
        createBy: sessionStorage.getItem('userId')
      })
      if (res.statusCode !== '200') return this.$message.error('新增失败')
      this.orderDialogotherexpenses = false
      this.addorderotter = {}
      this.getOrderList()
    },
    // 搜索订单号搜索订单号
    searchorderNo (data) {
      console.log(data)
    },
    // 确定订单号
    selorderNo (data) {
      console.log(data)
      this.addorderotter.orderNo = data.orderNo
      this.isVerification = false
    },
    // 点击添加按钮触发
    add () {
      this.orderDialogotherexpenses = true
    },
    // 点击查看详情按钮触发
    look (v) {
      this.orderForm = v
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    async ahead (v) {
      const { data: res } = await this.$http.post('/pmService/tInfoDispatchFee/deleteTInfoDispatchFee', {
        uuid: v.uuid
      })
      this.getOrderList()
    }
  }

}
</script>
<style lang="less" scoped>
.orderCost {
  width: 100%;
  height: 100%;
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-top: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
      .order-dialog {
        .cflex {
          display: flex;
          input {
            border: 0;
            outline: none;
            padding-left: 20px;
          }
        }
        .xbrogcorf {
          background-color: #fff;
          display: flex;
          border-bottom: #f3f6fa 2px solid;
        }
        .dialog-Title {
          color: #0097fe;
          font-size: 20px;
          font-weight: 700;
        }
        /deep/ .el-form {
          .subtitle {
            color: #0097fe;
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 20px;
            padding-left: 6px;
            border-left: 5px solid #0097fe;
          }
          .el-row {
            display: flex;
            .el-form-item {
              flex: 1;
              display: flex;
              .el-form-item__content {
                flex: 1;
                .el-input {
                  width: 100%;
                  .el-input__inner {
                    width: 100%;
                  }
                }
                .el-select {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
